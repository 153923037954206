import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LoginCallback } from "@okta/okta-react";

import Header from "../../components/header";
import Footer from "../../components/footer";
import { isInCustomerManagement } from "../../util/router-utils";
import Login from "./pages/login";
import Registration from "./pages/registration";
import ForgotPassword from "./pages/forgot-password";
import ResetConfirmation from "./pages/reset-confirmation";
import ResetPassword from "./pages/reset-password";
import AccountVerification from "./pages/account-verification";
import AdminLogin from "./pages/admin-login";
import OktaLogin from "./pages/okta-login";

import { default as Profile } from "./pages/user-profile";
import { Alert, Box, Text } from "@thecb/components";
import { profileState } from "../../util/state";

const LOGIN = "/login";
const ADMIN_LOGIN = "/admin/login";
const ADMIN_OKTA_LOGIN = "/admin/login/okta";
const ADMIN_OKTA_LOGIN_CALLBACK = "/admin/login/okta/callback";
const REGISTRATION = "/registration";
const FORGOT_PASSWORD = "/forgot-password";
const RESET_PASSWORD = "/reset-password";
const RESET_CONFIRMATION = "/reset-confirmation";
const RESET_SUCCESS = "/reset-success";
const VERIFY_ACCOUNT = "/verify-account/*";
const PROFILE_FIND = "/profile/find/*";
const PROFILE_WELCOME = "/welcome/*";
const PROFILE_SUPPORT = "/support/*";
const PROFILE_SETTINGS = "/profile/settings/*";
const PROFILE_WALLET_SETTINGS = "/profile/wallet/*";
const PROFILE_ACCOUNTS = "/profile/accounts/*";
const PROFILE_PROPERTIES = "/profile/properties/*";
const PROFILE_TRANSACTION_HISTORY = "/profile/history/*";
const CUSTOMER_MANAGEMENT = "/admin/:profileId";
const CUSTOMER_MANAGEMENT_SETTINGS = CUSTOMER_MANAGEMENT + PROFILE_SETTINGS;
const CUSTOMER_MANAGEMENT_WALLET_SETTINGS =
  CUSTOMER_MANAGEMENT + PROFILE_WALLET_SETTINGS;
const CUSTOMER_MANAGEMENT_TRANSACTION_HISTORY =
  CUSTOMER_MANAGEMENT + PROFILE_TRANSACTION_HISTORY;
const CUSTOMER_MANAGEMENT_ACCOUNTS = CUSTOMER_MANAGEMENT + PROFILE_ACCOUNTS;
const CUSTOMER_MANAGEMENT_FIND = CUSTOMER_MANAGEMENT + PROFILE_FIND;
// const CUSTOMER_MANAGEMENT_PROPERTIES = CUSTOMER_MANAGEMENT + PROFILE_PROPERTIES;

const profileTitle = props => {
  const browserTab = props.clientMetadata?.data?.contactName;
  return props.walletEnabled ? browserTab : `${browserTab} Profile`;
};

const profileFavicon = props => props.clientMetadata?.data?.favicon ?? "";

const ConnectedProfile = Profile(profileState);

const RouteHelmet = ({
  metadata,
  useContactName,
  title,
  screendoorForm,
  projectIds
}) => {
  const { projectId } = useParams();
  return (
    <Helmet
      onChangeClientState={
        screendoorForm
          ? (_, addedTags) =>
              injectScreendoorScript(projectIds[projectId], addedTags)
          : () => {}
      }
    >
      {screendoorForm && (
        <script
          type="text/javascript"
          nonce="d72fca793effa8d4fa965bad10588592"
          src="//code.jquery.com/jquery-2.2.3.min.js"
        ></script>
      )}
      {screendoorForm && (
        <link
          href="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.css"
          rel="stylesheet"
        />
      )}
      {screendoorForm && (
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
        />
      )}
      {screendoorForm && (
        <script
          type="text/javascript"
          nonce="d72fca793effa8d4fa965bad10588592"
          src="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.js"
        ></script>
      )}
      <title>
        {useContactName ? `${metadata?.contactName} ${title}` : title}
      </title>
      <link rel="shortcut icon" href={metadata?.favicon ?? ""} />
    </Helmet>
  );
};

const showCustomerManagementHeader = ({ userFirstName, userLastName }) => (
  <Fragment>
    <Box
      padding="0"
      extraStyles="position: fixed; top: 0; width: 100%; z-index: 1000;"
    >
      <Alert
        variant="warn"
        showQuitLink={false}
        padding="0"
        height="56px"
        maxContentWidth="78.5rem"
        textOverride={
          <Fragment>
            <Text variant="pS">
              You are viewing on behalf of{" "}
              {userFirstName && userLastName
                ? `${userFirstName} ${userLastName}.`
                : "..."}
            </Text>
            <Box onClick={() => window.close()} extraStyles="cursor: pointer;">
              <Text variant="pS" extraStyles="text-decoration: underline;">
                Go back
              </Text>
            </Box>
          </Fragment>
        }
      />
    </Box>
    <Box minHeight="56px" />
  </Fragment>
);

const ProfilePageContainer = ({
  onLogout,
  isLoggedIn,
  userFirstName,
  userLastName,
  isAuthenticatedAdmin,
  profileId,
  children
}) => {
  const isManagingCustomer = isAuthenticatedAdmin && isInCustomerManagement;
  return (
    <Fragment>
      {isManagingCustomer &&
        showCustomerManagementHeader({ userFirstName, userLastName })}
      <Header
        key="main-header"
        isLoggedIn={
          (!isInCustomerManagement && isLoggedIn) || isManagingCustomer
        }
        onLogout={onLogout}
        profileId={profileId}
      />
      {children}
      <Footer
        key="main-footer"
        showLogout={!isInCustomerManagement && isLoggedIn}
        onLogout={onLogout}
      />
    </Fragment>
  );
};

export const routes = {
  [LOGIN]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        useContactName
        title="Login"
      />
      <ProfilePageContainer
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <Login {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [ADMIN_LOGIN]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        useContactName
        title="Customer Management Login"
      />
      <ProfilePageContainer
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        onLogout={props.onAdminLogout}
        isLoggedIn={props.isLoggedIn}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <AdminLogin admin {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [ADMIN_OKTA_LOGIN]: props => <OktaLogin oidc={props.oidc} />,
  [ADMIN_OKTA_LOGIN_CALLBACK]: () => <LoginCallback />,
  [REGISTRATION]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        useContactName
        title="Registration"
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <Registration {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [FORGOT_PASSWORD]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        useContactName
        title="Forgot Password"
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ForgotPassword {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [RESET_PASSWORD]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        useContactName
        title="Reset Password"
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ResetPassword {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [RESET_CONFIRMATION]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        useContactName
        title="Password Reset"
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ResetConfirmation showMessage="reset-confirm" {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [RESET_SUCCESS]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        useContactName
        title="Password Reset"
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ResetConfirmation showMessage="reset-success" {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [VERIFY_ACCOUNT]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        useContactName
        title="Account Verification"
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <AccountVerification {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [PROFILE_SETTINGS]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title={profileTitle(props)}
        favicon={profileFavicon(props)}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [PROFILE_WALLET_SETTINGS]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title={profileTitle(props)}
        favicon={profileFavicon(props)}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [PROFILE_ACCOUNTS]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title={profileTitle(props)}
        favicon={profileFavicon(props)}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [PROFILE_PROPERTIES]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title={profileTitle(props)}
        favicon={profileFavicon(props)}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [PROFILE_FIND]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title={profileTitle(props)}
        favicon={profileFavicon(props)}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [PROFILE_TRANSACTION_HISTORY]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title={profileTitle(props)}
        favicon={profileFavicon(props)}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [CUSTOMER_MANAGEMENT_SETTINGS]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title="Customer Management"
        useContactName
        metadata={props.clientMetadata?.data}
      />
      <ProfilePageContainer
        onLogout={props.onAdminLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
        profileId={props.profileId}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} admin />
      </ProfilePageContainer>
    </Fragment>
  ),
  [CUSTOMER_MANAGEMENT_WALLET_SETTINGS]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title="Customer Management"
        useContactName
        metadata={props.clientMetadata?.data}
      />
      <ProfilePageContainer
        onLogout={props.onAdminLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
        profileId={props.profileId}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} admin />
      </ProfilePageContainer>
    </Fragment>
  ),
  [CUSTOMER_MANAGEMENT_TRANSACTION_HISTORY]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title="Customer Management"
        useContactName
        metadata={props.clientMetadata?.data}
      />
      <ProfilePageContainer
        onLogout={props.onAdminLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
        profileId={props.profileId}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} admin />
      </ProfilePageContainer>
    </Fragment>
  ),
  [PROFILE_WELCOME]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title={profileTitle(props)}
        favicon={profileFavicon(props)}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [PROFILE_SUPPORT]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title={profileTitle(props)}
        favicon={profileFavicon(props)}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [CUSTOMER_MANAGEMENT_ACCOUNTS]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title="Customer Management"
        useContactName
        metadata={props.clientMetadata?.data}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} admin />
      </ProfilePageContainer>
    </Fragment>
  ),
  [CUSTOMER_MANAGEMENT_FIND]: (props, childRoutes) => (
    <Fragment>
      <RouteHelmet
        title="Customer Management"
        useContactName
        metadata={props.clientMetadata?.data}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
        userLastName={props.userLastName}
        isAuthenticatedAdmin={props.isAuthenticatedAdmin}
      >
        <ConnectedProfile {...props} childRoutes={childRoutes} admin />
      </ProfilePageContainer>
    </Fragment>
  )
  /*
   [CUSTOMER_MANAGEMENT_PROPERTIES]: (props, childRoutes) => (
     <Fragment>
       <RouteHelmet
         title="Customer Management"
         useContactName
         metadata={props.clientMetadata?.data}
       />
             <ProfilePageContainer
                onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
        userFirstName={props.userFirstName}
      userLastName={props.userLastName}
      isAuthenticatedAdmin={props.isAuthenticatedAdmin}>
        <ConnectedProfile {...props} childRoutes={childRoutes} admin />
      </ProfilePageContainer>
     </Fragment>
   ),
  */
};
